@import "@design";

.fiber-locations {
  display: flex;
  flex-wrap: wrap;
  .location-item {
    $w: 100% / 8;
    flex: 0 0 calc($w - 2px);
    width: calc($w - 2px);
    border-style: solid;
    border-color: #6e6e6e !important;
    border-width: 1px !important;
    border-radius: 0;
    margin: 1px;
    padding: 5px;
    min-height: 130px;
    .permit-hours {
      font-size: 13px;
    }
    .new-permit-icon {
      top: 0;
      right: 0;
      position: absolute;
    }
  }
}
