@import "@design";

.locations-list {
  height: 100%;
}
.scheduler {
  color: $gray-dark;
  background-color: white;
  $status-border-width: 4px;
  $night: #136085;
  .shift {
    padding-left: $status-border-width * 2;
    border-radius: 4px;
    &.protected {
      position: relative;
      overflow: hidden;
      &::before {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        display: block;
        width: $status-border-width;
        content: '';
        background-color: $red;
      }
      .left-icon {
        position: absolute;
        bottom: 2px;
        left: $status-border-width;
      }
    }
    &.night-shift {
      // background: white;
      border: 1px solid $night;
      .night-shift-icon {
        position: absolute;
        right: 2px;
        bottom: 2px;
        transform: rotate(30deg);
      }
    } // .night-shift
    &.on-hold {
      .on-hold-icon {
        position: absolute;
        top: 2px;
        right: 2px;
      }
    }
    &.has-notes {
      .has-notes-icon {
        position: absolute;
        top: 2px;
        left: 4px;
      }
      &.protected {
        .has-notes-icon {
          left: 8px;
        }
      }
    }
  }
  .location-item {
    &.night-shift {
      border: 2px solid $night;
    }
  }
}
